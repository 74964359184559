
import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import FileUpload from "@/components/scope/file-upload.vue";
import { Table } from "@/utils/decorator";
import { emptyToLine } from "@/utils/common";
import dayjs from "dayjs";
import t from "@common/src/i18n/t";
const campaign = namespace("campaign");
const base = namespace("base");

@Component({
  components: { FileUpload }
})
@Table("loadListData")
export default class CampaignAnalysis extends Vue {
  @campaign.Action queryLotteryJoinList;
  @campaign.Action queryLotteryStatistic;
  @campaign.Action updateAwardSendStatus;
  @campaign.Action queryLotteryDetail;
  @base.Action getDictionaryList;
  emptyToLine = emptyToLine;
  dayjs = dayjs;
  t = t;
  get breadData() {
    return [
      { name: this.campaignTypeEnum[this.campaignType], path: this.campaginPrevPathEnum[this.campaignType] },
      {
        name: t("v210801.activity-data-analysis")
      }
    ];
  }
  searchForm: any = {
    customerName: "",
    customerMobile: "",
    detailStatus: ""
  };
  get searchList() {
    return [
      {
        label: t("finance.guest-names"),
        type: "input",
        value: "",
        prop: "customerName"
      },
      {
        label: t("finance.mobile"),
        type: "input",
        value: "",
        prop: "customerMobile"
      },
      {
        label: t("v210831.activity-participation-status"),
        type: "select",
        selectList: this.gameStatusList,
        value: "",
        prop: "detailStatus"
      }
    ];
  }
  campaginPrevPathEnum: any = {
    lottery: "/marketing/campaign/lottery",
    collect: "/marketing/campaign/collect-customer"
  };
  campaignTypeEnum: any = {
    lottery: t("v210801.lucky-draw"),
    collect: t("v210801.customer-accumulation-activities")
  };
  get id() {
    return this.$route.params.id;
  }
  get campaignType() {
    return this.$route.query.campaignType as string;
  }
  created() {
    this.init();
  }
  campaignCustomerList: any = [];
  gameStatusList: any = [];
  statisticData: any = [{}, {}, {}];
  get lookToReceiveRate() {
    try {
      if (Number(this.statisticData[0].count) && Number(this.statisticData[1].count)) {
        return ((this.statisticData[1].count / this.statisticData[0].count) * 100).toFixed(2);
      } else {
        return "0";
      }
    } catch {
      return "0";
    }
  }
  get ReceiveToUsedRate() {
    try {
      if (Number(this.statisticData[1].count) && Number(this.statisticData[2].count)) {
        return ((this.statisticData[2].count / this.statisticData[1].count) * 100).toFixed(2);
      } else {
        return "0";
      }
    } catch {
      return "0";
    }
  }
  get lookToUsedRate() {
    try {
      if (Number(this.statisticData[0].count) && Number(this.statisticData[2].count)) {
        return ((this.statisticData[2].count / this.statisticData[0].count) * 100).toFixed(2);
      } else {
        return "0";
      }
    } catch {
      return "0";
    }
  }
  handleSendAward(row) {
    this.updateAwardSendStatus({
      campaignDetailCode: row.campaignDetailCode
    }).then(data => {
      this.$message.success(t("v210831.prizes-have-been"));
      this.loadListData();
    });
  }
  campaignData: any = {};
  init() {
    this.loadListData();
    this.getDictionaryList("campaign_detail_game_status").then(data => {
      this.gameStatusList = data;
    });
    this.queryLotteryStatistic({
      campaignCode: this.id
    }).then(data => {
      this.statisticData = data.data.statisticArray;
    });
    this.queryLotteryDetail({
      campaignCode: this.id
    }).then(data => {
      this.campaignData = data.data;
    });
  }
  @Table.LoadDataMethod()
  loadListData() {
    return this.queryLotteryJoinList({
      campaignCode: this.id,
      ...this.searchForm,
      pageNum: this.mix_pageNum,
      pageSize: this.mix_pageSize
    }).then(data => {
      this.campaignCustomerList = data.data.list;
      return data;
    });
  }
  handleSearch(data) {
    this.mix_pageNum = 1;
    data.forEach(item => {
      if (item.prop) {
        this.searchForm[item.prop] = item.value;
      }
    });
    this.loadListData();
  }
}
